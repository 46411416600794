import React from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import axios from "axios"
import { siteMetadata } from '../../gatsby-config'

//import ReactPlayer from 'react-player'
import ReactPlayer from 'react-player/lazy'
import Duration from './Duration'


import { setTimeout } from "timers";

import { isMobileSafari } from 'react-device-detect';


import InfoContainer from "./InfoContainer"

import Meta from './meta'

import Rate from "./Rating/Rate"


/* Estado inicial*/
const INITIALSTATE = {
  youtubeKey: null,
  medio: null,
  url: null,
  showingDetails: false,
  //------//
  playing: !isMobileSafari,
  pip: false,
  controls: true,
  light: false,
  volume: 1,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  progressBarStyle: {
    "backgroundImage":
      'linear-gradient(to right, #444 0%, rgba(4,4,4,0.5) 100%)'
  },
  width: 0, height: 0,
}


class Player extends React.Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  state = INITIALSTATE


  updateWindowDimensions() {
    if (this.player !== undefined && this.player) {
      let width = this.player.wrapper.clientWidth;
      let height = this.player.wrapper.clientHeight;
      this.setState({ width, height });
    }
  }

  componentWillUnmount() {
    this.props.HideInfoContainer();
    this.mounted = false
    clearTimeout(this.hideNavTimer);
    this.hideNavTimer = null;
    this.props.hideNavByScroll();
    this.props.RemoveFromPlayist(0);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  mounted = false;
  hideNavTimer = null;
  componentDidMount() {
    this.mounted = true;
    //console.log("PROPS PLAYER", this.props)
    const autoHide = !this.props.isMobile;
    //const autoHide = true;

    if (autoHide) {
      this.hideNavTimer = setTimeout(() => {
        if (this.mounted) {
          this.props.HideNav()
        }
      }
        , 5000);
      this.props.hideNavByTimeout();
    } else {
      this.props.hideNavByTimeout();
    }

    /*
    let currentMedio = [];
    if (this.props.playlist.medios && this.props.playlist.medios.length > 0) {
      currentMedio = currentMedio.concat(this.props.playlist.medios[0])
      currentMedio = currentMedio.pop()
      //console.log("currentMedio", currentMedio)
      this.setState({
        ...this.state,
        medio: currentMedio
      })

    } else
    */
    {
      const currentId = location.pathname.replace(/\/play\/player\/?/gi, "")
      //console.log("get medio from id", currentId);
      if (currentId) {
        this.fetchMedio(currentId);
      }

      if (location.hash.toLowerCase() == "#comments") {
        this.props.ShowInfoContainer("comentarios");
      }

    }


    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    const { playlist } = this.props
    let reloadMedio = this.props.AdminState.editandoMedio != prevProps.AdminState.editandoMedio
    if (reloadMedio
      || (playlist.medios.length > 0
        && this.state.medio
        && this.state.medio.IdMedio != playlist.medios[0].IdMedio)) {
      //como desde un inicio
      this.setState(INITIALSTATE)
      this.fetchMedio(playlist.medios[0].IdMedio)
    }
  }


  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }

  handlePlay = () => {
    this.setState({ playing: true })
  }

  handleStop = () => {
    this.setState({ url: null, playing: false })
  }

  handlePause = () => {
    this.setState({ playing: false })
  }

  handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }

  handleSeekChange = e => {
    try {
      this.setState({ played: parseFloat(e.target.value) })
    } catch (e) {

    }
  }

  handleSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }


  handleProgress = state => {
    try {
      // We only want to update time slider if we are not currently seeking
      if (this.state.medio != null && !this.state.seeking) {
        this.setState(state)
      }


      if (this.ProgressBar !== undefined) {
        let percent = this.ProgressBar.value * 100;
        let progressBarStyle = {
          ...this.state.progressBarStyle,
          "backgroundImage":
            'linear-gradient(to right, #ffbb11 0%, #ffd611 ' + percent + '%, #444 ' + percent + '%, rgba(40,40,40,0.85) 100%)'
        }
        this.setState({
          progressBarStyle: progressBarStyle
        })
      }
    }
    catch (ex) {
    }
  }

  handleStart = () => {
    //console.log("START PLAYER")
  }

  handleDuration = (duration) => {
    this.setState({ duration })
  }

  handleEnded = () => {
    this.setState({ playing: this.state.loop })
  }


  SetPlayerRef = player => {
    this.player = player;
    this.updateWindowDimensions();
  }

  SetProgressBarRef = slider => {
    this.ProgressBar = slider
  }


  getBackgroundImageSerie = (idSerie) => {
    const baseurl = siteMetadata.apiBaseURL;
    let url = "";
    if (this.player !== undefined && this.player) {

      let width = this.player.wrapper.clientWidth;

      if (idSerie > 0) {
        url = baseurl + `/images/series/${idSerie}/player.jpg?w=${width}`;
      }
    }
    return { "backgroundImage": `url('${url}')` }
  }

  render() {
    //console.log("state", this.state)
    var url = this.state.medio && this.state.medio.UrlExterno ? this.state.medio.UrlExterno : ""

    const isSoundCloud = url.indexOf("soundcloud.com") >= 0;

    const { playing,
      pip, controls,
      light, loop,
      playbackRate,
      volume, muted,
      played, duration,
      //----//
      showingDetails
    } = this.state


    return (
      <div>
        <Meta title={this.state.medio ? this.state.medio.Titulo : null}
          site={siteMetadata}
          imagenSrc={this.state.medio ? this.state.medio.ImagenAsociada : null}
        />
        <div id="PlayerMain">
          {this.state.medio && (!this.props.AdminState.editandoMedio) ?
            <div className={this.state.width > this.state.height ? "ReactPlayerContainer masAncho" : "ReactPlayerContainer masAlto"}


            >

              <ReactPlayer
                ref={this.SetPlayerRef}
                width="100%"
                height="100%"
                className="ReactPlayer"
                url={url}

                playing={playing}
                pip={pip}
                controls={controls}
                light={light}
                loop={loop}
                playbackRate={playbackRate}
                volume={volume}
                muted={muted}

                onMouseMove={(event) => {
                }}

                onPlay={this.handlePlay}
                onPause={this.handlePause}
                onEnded={this.handleEnded}
                onStart={this.handleStart}
                onProgress={this.handleProgress}
                onDuration={this.handleDuration}

              />



              {isSoundCloud ?
                <div className="overlayPlayerSoundCloud ReactPlayerContainer" >
                  <div className="d-flex w-100 h-100">
                    {this.state.medio.Serie > 0 ?
                      <div className="serieMediateca"
                        style={this.getBackgroundImageSerie(this.state.medio.Serie)}>
                        <h1> {this.state.medio.Titulo}  </h1>
                      </div>
                      :
                      <div className="row m-auto">
                        <img alt={this.state.medio.Titulo} src={this.state.medio.ImagenAsociada} />
                      </div>
                    }
                  </div>


                </div>
                :
                <div className="overlayPlayer" />
              }


              {isSoundCloud ?
                <div className="PlayerControls">
                  <button className="btn stop" onClick={this.handleStop}>
                    <i className="fa fa-stop" aria-hidden="true"></i></button>
                  <button className="btn playpause" onClick={this.handlePlayPause}>{playing ?
                    <i className="fa fa-pause" aria-hidden="true"></i>
                    :
                    <i className="fa fa-play" aria-hidden="true"></i>
                  }
                  </button>
                  <span className="PlayerTime"> <Duration seconds={duration} /> </span>

                  <input ref={this.SetProgressBarRef}
                    type='range' min={0} max={1} step='any'
                    value={played}
                    className="ProgressBarPlayer custom-rangexx"
                    onMouseDown={this.handleSeekMouseDown}
                    onChange={this.handleSeekChange}
                    onMouseUp={this.handleSeekMouseUp}

                    onTouchStart={this.handleSeekMouseDown}
                    onTouchEnd={this.handleSeekMouseUp}
                    style={this.state.progressBarStyle}
                  />
                  <span className="PlayerTime">  -<Duration seconds={(duration * (1 - played))} /> </span>
                </div>
                : null
              }

            </div>
            :

            <Meta site={siteMetadata} />

          }




        </div>
        <div className="InfoTabs">
          {this.state.medio ?
            <>
              <span className="float-left">{this.state.medio.Visualizaciones} Visualizaciones</span>
              <Rate medio={this.state.medio} />

              <button className={this.props.playlist.tabKey == "detalles" ? "active" : ""} onClick={() => {
                this.props.ShowInfoContainer("detalles")
              }} >Detalles</button>

              <button className={this.props.playlist.tabKey == "relacionados" ? "active" : ""} onClick={() => {
                this.props.ShowInfoContainer("relacionados")
              }}>Relacionados</button>


              <button className={this.props.playlist.tabKey == "comentarios" ? "active" : ""} onClick={() => {
                this.props.ShowInfoContainer("comentarios")
              }}>Comentarios</button>
            </>
            : null

          }
        </div>



        {this.state.medio ?
          <InfoContainer medio={this.state.medio} showingDetails={showingDetails} />

          : null

        }


      </div>
    )
  }

  fetchMedio = (id) => {
    const baseurl = siteMetadata.apiBaseURL;
    let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx?accion=getmedio&IdMedio=" + id



    axios
      .get(url)
      .then(({ data }) => {
        const { playlist } = this.props

        if (data) {
          this.setState({
            medio: data,
            playing: !isMobileSafari
          });
        }

        this.props.AddToPlaylist(data);
        /*if (playlist.medios.length == 0 || data.IdMedio != playlist.medios[0].IdMedio) {
        }*/
      })
      .catch(error => {
        console.log(error);
      })
  }
}

const mapStateToProps = (ReduxState) => {
  return {
    playlist: ReduxState.playlist,
    AdminState: ReduxState.admin
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideNavByTimeout: (query) => {
      dispatch({ type: `HIDENAVBYTIMEOUT`, query })
    },
    hideNavByScroll: (query) => {
      dispatch({ type: `HIDENAVBYSCROLL`, query })
    },
    HideNav: () => {
      dispatch({ type: 'HIDENAV' })
    },
    RemoveFromPlayist: (position) => {
      dispatch({ type: 'REMOVEFROMPLAYLIST', position })
    },
    ShowInfoContainer: (tabKey) => {
      dispatch({ type: "SHOWPLAYERINFOCONTAINER", tabKey })
    },
    HideInfoContainer: () => {
      dispatch({ type: "HIDEPLAYERINFOCONTAINER" })
    },
    AddToPlaylist: (medio) => {
      dispatch({ type: `ADDTOPLAYLIST`, medio })
    }
  }
}

const ConnectedPlayer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Player)



export default ConnectedPlayer
