import React from "react"
import { connect } from "react-redux"
import Moment from 'react-moment';
import Comentar from "./Comentar"

class Comentario extends React.Component {
  constructor(props) {
    super(props)

    this.handleResponder = this.handleResponder.bind(this)
    this.handleCambioEstado = this.handleCambioEstado.bind(this)

  }

  state = {
    responder: false
  }

  componentDidMount() {
  }



  handleCambioEstado(e) {
    const { comentario } = this.props
    this.props.CambiarEstadoComentario(comentario.IdComentario, event.target.value, this);
    comentario.IdEstadoComentario = event.target.value;
  }

  cambiarEstadoCallback(refComentario, idEstadoResultado) {
    const { comentario } = refComentario.props
    comentario.IdEstadoComentario = idEstadoResultado;
  }

  handleResponder() {
    this.setState({
      responder: true
    })

  }

  render() {
    const { comentario } = this.props
    const esAdmin = this.props.sessionState != null && this.props.sessionState.id
    let esRespuesta = false;
    if (this.props.esRespuesta !== undefined) {
      esRespuesta = this.props.esRespuesta
    }

    return (
      <>
        <div className="comentarioDivContainer">
          <div className="d-flex justify-content-between bd-highlight mb-2">
            <div className="comentarioNombre bd-highlight">
              {comentario.Nombre}
            </div>
            <div className="comentarioFecha bd-highlight">
              <Moment format="DD/MM/YYYY">{comentario.Fecha}</Moment>
            </div>
          </div>
          
          <div className="ComentarioTexto">
            {comentario.Texto.trim().split('\n').map((item, key) => {
              return (
                <p key={key}>{item}</p>
              )
            })}
          </div>
        </div>
        {!esRespuesta && esAdmin ?
          <div className="ControlesAdmin">
            <div className="Estado">
              <select value={comentario.IdEstadoComentario} onChange={this.handleCambioEstado}>
                <option value="1">PENDIENTE</option>
                <option value="2">APROBADO</option>
                <option value="3">RECHAZADO</option>
              </select>
            </div>
            <div className="Responder">
              {this.state.responder ?
                <Comentar IdMedio={comentario.IdMedio}
                  IdComentarioResponder={comentario.IdComentario}
                  Nombre="Mediateca - Biblioteca Nacional"
                  refComentario={this}
                />
                :
                <button onClick={this.handleResponder}> responder...</button>
              }
            </div>
          </div>
          :
          null
        }

      </>
    )
  }
}


const mapStateToProps = ({ comentarios, session }) => {
  return {
    comentariosState: comentarios,
    sessionState: session
  }
}

const mapDispatchToProps = dispatch => {
  return {
    CambiarEstadoComentario: (idComentario, nuevoEstado, refComentario) => {
      dispatch({ type: `CAMBIARESTADOCOMENTARIO`, idComentario, nuevoEstado, refComentario })
    }
  }
}

const ConnectedComentario = connect(
  mapStateToProps,
  mapDispatchToProps
)(Comentario)

export default ConnectedComentario
