import React from "react"
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { connect } from "react-redux"
import { isFirefox } from 'react-device-detect';
import Atributo from "./Atributo"
import AtributosPredefinidos from "../utils/AtributosPredefinidos"
import Comentarios from "./Rating/Comentarios"
import Share from "./Share"
import Medio from "./medio"
import RSC from "react-scrollbars-custom";

import { isMobile } from 'react-device-detect';


class InfoContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    tabKey: "detalles"
  }

  setTabKey = (k) => {
    this.props.ShowInfoContainer(k)
  }

  setRelacionadosRef = (container) => {
    this.RelacionadosContainer = container;
  }


  procesarAtributosAdicionales = (medio) => {
    let keys = Object.keys(medio.AtributosAdicionales)
    return (
      keys.map((key, i) => {
        let currentAtributo = medio.AtributosAdicionales[key];
        let value = null;
        if (currentAtributo) {
          //console.log("currentAtributo", currentAtributo);
          switch (currentAtributo.Tipo.toLowerCase()) {
            case "url":
              let url = currentAtributo.Value;
              value = (
                <a target="_blank"
                  href={url.match(new RegExp("http://", "i"))
                    ? url : `http://${url}`}
                >{url}</a>
              )
              //console.log("url", url)
              break;
            default:
              value = <span className="AtributoValor">{currentAtributo.Value}</span>

          }
        }

        return (
          <li key={i}>
            <span className="Label">{currentAtributo.Label}</span>
            {value}
          </li>)
      })
    )
  }

  render() {
    const { medio, showingDetails } = this.props

    let clases = ["InfoContainer", "w-100", "px-2", "py-2"]
    if (showingDetails) {
      clases.push("active");
    }
    if (isFirefox) {
      clases.push("isFirefox");
    }


    let SinopsisContent = (
      <div className="SinopsisMedio">
        {medio.Sinopsis
          .split('\n')
          .map(
            (item, key) => {
              return <p key={key}>{item}</p>
            }
          )
        }
      </div>
    )

    //console.log("props", this.props);

    //this.props.HideInfoContainer()
    return (
      <div className={clases.join(" ")} >

        <Share
          socialConfig={{
            twitterHandle: '@biblioflix',
            config: {
              url: "/play/player/" + this.props.medio.IdMedio,
              title: this.props.medio.Titulo,
            },
          }}
        />

        <div className="InfoHeader">
          <button className="closeBtn btn" onClick={() => { this.props.HideInfoContainer() }}><i className="fa fa-times"></i><span className="d-none">Close</span></button>
          <div className="InfoTabs">
            <button
              className={this.props.tabKey == "detalles" ? "active" : ""}
              onClick={() => { this.setTabKey("detalles") }}>Detalles</button>
            <button
              className={this.props.tabKey == "relacionados" ? "active" : ""}
              onClick={() => { this.setTabKey("relacionados") }}>Relacionados</button>
            <button
              className={this.props.tabKey == "comentarios" ? "active" : ""}
              onClick={() => { this.setTabKey("comentarios") }}>Comentarios</button>
          </div>
        </div>



        <div className="InfoBody">
          <Tabs id="controlled-tab-example" activeKey={this.props.tabKey}
            onSelect={k => this.setTabKey(k)}
          >
            <Tab eventKey="detalles" title="Detalles">
              <div className="tituloTabContainer px-2 py-2">Detalles</div>
              <div className="LogoSerie"></div>
              <h1>{medio.Titulo}</h1>


              {isMobile || medio.Sinopsis.length < 1024 ?
                <div> { SinopsisContent } </div>
                :
                <RSC noDefaultStyles style={{ width: "100%", height: "calc(50vh - 4.5rem )" }}>
                  {SinopsisContent}
                </RSC>
              }

              <div className="AtributosPredefinidos">
                <ul>
                  {AtributosPredefinidos.map((currentAtributo, i) => {
                    return (
                      <Atributo key={i} atributo={currentAtributo} medio={medio} />
                    )
                  })}
                  {this.procesarAtributosAdicionales(medio)}
                </ul>
              </div>

              {
                //<ArbolCategorias />
              }
            </Tab>
            <Tab eventKey="relacionados" title="Relacionados">
              <div className="tituloTabContainer  px-2 py-2">Relacionados</div>
              <h1>Relacionados</h1>

              <div ref={this.setRelacionadosRef} className="relacionados mediosResult row">
                <div className="col">
                  {medio.Relacionados ? medio.Relacionados.map((medioRelacionado, i) => {
                    return (
                      <div key={i} className="medioResult">
                        <Medio parentContainer={this.RelacionadosContainer} medio={medioRelacionado} />
                      </div>
                    )
                  })
                    : null}
                </div>
              </div>

            </Tab>
            <Tab eventKey="comentarios" title="Comentarios">
              <div className="tituloTabContainer  px-2 py-2">Comentarios</div>
              <h1 hidden>Comentarios</h1>
              <Comentarios IdMedio={medio.IdMedio} />
            </Tab>
          </Tabs>
        </div>


      </div>
    )
  }
}

const mapStateToProps = (ReduxState) => {
  return {
    showingDetails: ReduxState.playlist.showingDetails,
    tabKey: ReduxState.playlist.tabKey
  }
}

const mapDispatchToProps = dispatch => {
  return {
    HideInfoContainer: () => {
      dispatch({ type: "HIDEPLAYERINFOCONTAINER" })
    },
    ShowInfoContainer: (tabKey) => {
      dispatch({ type: "SHOWPLAYERINFOCONTAINER", tabKey })
    },
  }
}

const ConnectedInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoContainer)



export default ConnectedInfoContainer


