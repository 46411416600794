export default [
/*  {
    label: "Formato",
    type: "categoria",
    idCategoria: 3
  },*/
  {
    label: "Descriptores",
    field: "Descriptores"
  },
  {
    label: "Duración",
    field: "Duracion",
    type: "duracion"
  },
  {
    label: "Fecha de publicación",
    field: "Publicacion",
    type: "date"
  },
  /*{
    label: "Idioma original",
    type: "categoria",
    idCategoria: 3107
  },*/
  /*{
    label: "Idioma de Subtítulo",
    type: "categoria",
    idCategoria: 5
  },*/
  /*{
    label: "Lesco",
    type: "categoria",
    idCategoria: 37
  },
  {
    label: "Nombre de asignatura",
    type: "categoria",
    idCategoria: 83
  },
  {
    label: "Dependencia solicitante",
    type: "categoria",
    idCategoria: 11
  },
  {
    label: "Dependencia externa",
    field: "DependenciaExterna",
  },
  {
    label: "Cátedra",
    type: "categoria",
    idCategoria: 82
  },
  {
    label: "Especialista de contenidos",
    field: "especialistadecontenidos",
    type: "AtributosPredefinidos"
  },
  {
    label: "Nombre de productor",
    type: "categoria",
    idCategoria: 10
  },
  {
    label: "Nombre de realizador",
    field: "nombrederealizadoraorealizador",
    type: "AtributosPredefinidos"
  },
  {
    label: "Invitados",
    field: "invitadosoinvitadas",
    type: "AtributosPredefinidos"
  },*/
  {
    label: "Sitio web",
    field: "sitioweb",
    type: "AtributosPredefinidos"
  },
  {
    label: "Redes sociales",
    field: "redessociales",
    type: "AtributosPredefinidos"
  },
  {
    label: "Restricciones de uso",
    field: "restriccionesdeuso",
    type: "AtributosPredefinidos"
  },
  /*{
    label: "Galardones",
    type: "categoria",
    idCategoria: 2
  },*/
  {
    label: "Responsable de publicación",
    field: "Responsable"
  },
]
